'use strict';

angular.module('kljDigitalLibraryApp')

.service('OpenedTabService', ["common", "appConfig", "$q", function(common, appConfig, $q) {

    this.openedTab = [];


    //check if id already present
    this.isIdPresent = function(dataset, id) {

        for (var index in dataset) {

            if (dataset[index].id === id) {
                return true;
            }

        }

        return false;
    }
    this.getIndex = function(dataset, id) {
        var index = -1;
        var case_index = dataset.find(function(item, i) {
            if (item.id === id) {
                index = i;
                return i;
            }
        });
        return index;
    }
    this.getResponseIndex = function(dataset, id) {
        var index = -1;
        var case_index = dataset.find(function(item, i) {
            if (item._id === id) {
                index = i;
                return i;
            }
        });
        return index;
    };

    //Add one tab to opened tab
    this.push = function(id) {

        if (!this.isIdPresent(this.openedTab, id.id)) {
            this.openedTab.push(id);
        }

        return this.openedTab;
    }


    //remove all opened tab
    this.removeAll = function() {

        this.openedTab = [];
        return this.openedTab;
    }


    //remove specific case from opened tab
    this.remove = function(id) {
        var openedTab = [];

        for (var index in this.openedTab) {

            if (id !== this.openedTab[index].id) {
                openedTab.push(this.openedTab[index]);
            }

        }

        this.openedTab = openedTab;
        return this.openedTab;
    }


    //get all opened tab
    this.getAll = function() {

        return this.openedTab;
    }

    //append array of case id to opened tab
    this.append = function(dataset) {

        if (!dataset) {

            dataset = [];

        }

        for (var index in dataset) {
            if (!this.isIdPresent(this.openedTab, dataset[index])) {
                this.openedTab.push({
                    "id": dataset[index],
                    "case_note": false
                });
            }
        }

        return this.openedTab;
    }



}])